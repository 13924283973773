body, html {
  margin:0;
  background-color: #dde8f2;

}

body{
  min-width:100%;
  min-height:100%;
}
